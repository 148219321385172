<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
>

<div v-if="order">
  <b-form @submit.prevent="submitForm" id="screen-content">
    <b-row class="match-height">
      <b-col cols="12" md="12" class="order-details">
        <b-card
            :title="$t('orderDetails')"
        >
            <b-row>
                <b-col cols="12" md="6">
                <b-form-group
                    :label="$t('number')"
                >
                    <b-form-input
                    :value="order.number" 
                    disabled
                    />
                </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                <b-form-group
                    :label="$t('date')"
                >
                    <b-form-input
                    :value="order.date | date_format('DD/MM/YYYY HH:mm:ss')" 
                    disabled
                    />
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    :label="$t('amount')"
                >
                    <b-form-input
                    :value="order.amount_incl_tax" 
                    disabled
                    />
                </b-form-group>
                </b-col>
                <b-col md="6">
                <b-form-group
                    :label="$t('status')"
                >
                    <b-form-select
                        v-model="order.state"
                    >
                        <b-form-select-option 
                            v-for="(status, index) in orderStatuses"
                            :key="index"
                            :value="status.value"
                        >
                            {{ $t(status.text) }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                </b-col>
            </b-row>
        </b-card>
      </b-col>

      <b-col md="6">

        <b-card
            :title="$t('billingDetails')"
        >
            <b-row>
              <b-col md="4">
                <div>{{ $t('name') }}</div>
              </b-col>
              <b-col md="8">
                <div>{{ order.buyer.company.name }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <div>{{ $t('companyPurpose') }}</div>
              </b-col>
              <b-col md="8">
                <div>{{ order.buyer.company.purpose }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <div>{{ $t('address') }}</div>
              </b-col>
              <b-col md="8">
                <div>{{ order.buyer.company.address }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <div>{{ $t('taxId') }}</div>
              </b-col>
              <b-col md="8">
                <div>{{ order.buyer.company.tax_id }}</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <div>{{ $t('taxOffice') }}</div>
              </b-col>
              <b-col md="8">
                <div>{{ order.buyer.company.tax_office }}</div>
              </b-col>
            </b-row>
        </b-card>
      </b-col>

      <b-col md="6">

        <b-card
            :title="$t('shippingAddress')"
        >
            <b-row>
                <b-col md="4">
                  <div>{{ $t('street') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.street }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div><div>{{ $t('number') }}</div></div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.number }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('area') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.area }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('city') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.city }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('postalCode') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.postcode }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('state') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.state }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('country') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.country }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div class="mt-1">{{ $t('fullName') }}</div>
                </b-col>
                <b-col md="8">
                  <div class="mt-1">{{ order.addresses.shipping_address.firstName }} {{ order.addresses.shipping_address.lastName }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('phone') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.phone }}</div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                  <div>{{ $t('comments') }}</div>
                </b-col>
                <b-col md="8">
                  <div>{{ order.addresses.shipping_address.comments }}</div>
                </b-col>
            </b-row>
        </b-card>
      </b-col>

      <b-col cols="12">

        <b-card
            :title="$t('Products')"
        >
          <b-table
            :items="order.products"
            responsive
            class="position-relative products-table"
            :no-provider-sorting="true"
            :fields="tableColumns"
          >
          </b-table>
          
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pb-1 button-row">
      <b-col class="text-right"> 
        <b-button
          @click.prevent="printOrder"
          variant="outline-primary"
          class="mr-1"
        >
          {{ $t('Print') }}
        </b-button>

        <b-button
          type="submit"
          variant="primary"
        >
          {{ $t('submit') }}
        </b-button>
        
      </b-col>
    </b-row>
  </b-form>
  <div id="print-content" class="m-0 p-0">
    <b-row>
      <b-col cols="3">
        <b-img left :src="require('@/assets/images/logo/logo.png')" width="180"></b-img>
      </b-col>
      <b-col cols="6" class="text-center">
        <h2>{{ $t('Order') }} #{{ order.number }}</h2>
        {{ order.date | date_format('DD/MM/YYYY - HH:mm:ss') }}<br>
        {{ $t('status') }}: {{ $t(orderStatusTexts[order.state]) }}
      </b-col>
      <b-col cols="3">
        <b-img v-if="supplierLogo != null" right :src="supplierLogo" class="print-supplier-logo"></b-img>
      </b-col>
    </b-row>
    <b-row style="border: 1px solid;" class="p-2 my-2">
      <b-col cols="4" class="p-0">
        <h6><b>{{ $t('billingDetails') }}:</b></h6>
        {{ order.buyer.company.name }}<br>
        {{ order.buyer.company.purpose }}<br>
        {{ order.buyer.company.address }}<br>
        {{ order.buyer.company.tax_id }}<br>
        {{ order.buyer.company.tax_office }}<br>
      </b-col>
      <b-col cols="5" class="p-0 pl-2">
        <h6><b>{{ $t('shippingAddress') }}:</b></h6>
        {{ order.addresses.shipping_address.street }} {{ order.addresses.shipping_address.number }}<br>
        <span v-if="order.addresses.shipping_address.area">{{ order.addresses.shipping_address.area }}<br></span>
        {{ order.addresses.shipping_address.city }}<br>
        {{ order.addresses.shipping_address.postcode }}<br>
        {{ order.addresses.shipping_address.state }}<br>
      </b-col>
      <b-col cols="3" class="p-0 pl-1">
        <h6><b>{{ $t('shippingDetails') }}:</b></h6>
        <span v-if="order.addresses.shipping_address.firstName || order.addresses.shipping_address.lastName">{{ order.addresses.shipping_address.firstName + order.addresses.shipping_address.lastName }}<br></span>
        <span v-if="order.addresses.shipping_address.phone">{{ order.addresses.shipping_address.phone }}<br></span>
        <span v-if="order.addresses.shipping_address.comments"><br>{{ $t('comments') }}:<br>{{ order.addresses.shipping_address.comments }}</span>
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col cols="12">
        <b-table
          :items="order.products"
          responsive
          class="position-relative products-table"
          :no-provider-sorting="true"
          :fields="tableColumns"
        >
        </b-table>
        </b-col>
    </b-row>
  </div>
  
</div>
</b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption,
  BTable, BImg, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { orderStatuses } from '@/../appConfig.js'
import store from '@/store'
import moduleOrders from '@/store/orders/moduleOrders.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BImg,
    BOverlay,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      order: null,
      orderStatuses: orderStatuses,
      orderStatusTexts: orderStatuses.reduce((obj, item) => Object.assign(obj, { [item.value]: item.text }), {}),
      tableColumns: [
        { key: 'name', label: this.$t('name') },
        { key: 'sku'},
        { key: 'price', label: this.$t('price') },
        { key: 'qty', label: this.$t('quantityShort') },
        { key: 'row_total', label: this.$t('total') },
        { key: 'row_tax', label: this.$t('taxAmount') },
        { key: 'row_total_incl_tax', label: this.$t('total_incl_tax')},
      ],
      totalsRow: null,
      supplierLogo: null,   
    }
  },
  methods:{
    submitForm(){
      this.loading = true    
      this.$store.dispatch("orders/editOrder", this.order)
        .then( () => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Order successfully updated"),
                icon: 'CheckIcon',
                variant: 'success',
            },
          })
        } )
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t("Error updating order"),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
          })
        }) 
        .finally(()=>{this.loading = false})
    },
    printOrder(){
      window.print()
    }
  },
  created() {
    this.loading = true   
    if (!store.hasModule('orders')) store.registerModule('orders', moduleOrders)
    
    let order = this.$store.getters['orders/order'](this.id)
    if(typeof order != "undefined"){
      this.order = Object.assign({}, order);
      this.order.products.forEach((product) => {
        product.row_tax = parseFloat(product.row_total_incl_tax-product.row_total).toFixed(2);
      })
      this.order.products.push({row_total: order.amount, row_tax: order.tax_amount, row_total_incl_tax: order.amount_incl_tax})
      this.loading = false
    }
    else{
      this.$store.dispatch("orders/getOrder", this.id)
        .then( (order) => {
          this.order = order;
          this.order.products.forEach((product) => {
            product.row_tax = parseFloat(product.row_total_incl_tax-product.row_total).toFixed(2);
          })
          this.order.products.push({row_total: order.amount, row_tax: order.tax_amount, row_total_incl_tax: order.amount_incl_tax})
        })
        .catch(() => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Error fetching orders"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
        .finally(()=>{this.loading = false})    
    }
    
    if(Object.keys(this.$store.state.user.supplierDetails).length !== 0){
      this.supplierLogo = this.$store.state.user.supplierDetails.logo
    } else {
      this.$store.dispatch('user/getSupplierDetails')
        .then(() => {
          this.supplierLogo = this.$store.state.user.supplierDetails.logo
        })
    }
  },
  
}
</script>

<style lang="scss">

#print-content {
  display: none;
}

.products-table th:first-of-type{
  width: 40%;
}

.print-supplier-logo {
  max-width: 200px;
  max-height: 50px;
  width: auto;
  height: auto;
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding: 2rem !important;
  }
  .content-header {
    display: none;
  }
  footer.footer {
    display: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  .btn-scroll-to-top {
    display: none !important;
  }

  // Order Specific Styles

  #print-content {
  display: block;
  }

  #screen-content {
    display: none;
  }

  .table td, .table th {
    padding: 0.72rem 1rem !important;
  }

}
</style>